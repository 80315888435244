<template>
  <div style="background: transparent linear-gradient(130deg, #FFFC81 0%, #7EFF86 100%) 0% 0% no-repeat padding-box;">
    <div style="background: transparent linear-gradient(231deg, #F1AB85 0%, #EF8885 100%) 0% 0% no-repeat padding-box; border-radius: 0px 0px 90px 90px;">
      <v-container fluid class="v-container-fluid">
        <h2 class="mon-bold" style="font-size: min(max(35px, 5vw), 70px); margin: auto; text-align: center; max-width: 812px; color: white; text-align: center; margin-top: 60px;">{{ texts.buyer.titleSection }}</h2>
        <h4 class="mon" style="font-size: min(max(16px, 5vw), 26px); margin: auto; text-align: center; max-width: 812px; color: white; text-align: center; font-weight: 500;">{{ texts.buyer.subtitleSection }}</h4>
        <div style="display: flex; justify-content: space-evenly; margin-top: 20px; flex-wrap: wrap; gap: 20px;">
          <div style="background: #FFFFFF50 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000029; border-radius: 8px; width: 217px; height: 201px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
            <div style="display: flex; flex-direction: column; align-items: center;">
              <v-img src="@/assets/buyer/PNG/folder.png" alt="Descripción de la imagen" contain max-width="60" max-height="50"></v-img>
              <h4 class="mon-bold" style="margin-top: 15px; text-align: center; color: white; font-size: 22px; text-align: center; font-weight: 500;">{{ texts.buyer.Catalog }}</h4>
            </div>
          </div>
          <div style="background: #FFFFFF50 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000029; border-radius: 8px; width: 217px; height: 201px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
            <div style="display: flex; flex-direction: column; align-items: center;">
              <v-img src="@/assets/buyer/PNG/archive.png" alt="Descripción de la imagen" contain max-width="60" max-height="50"></v-img>
              <h4 class="mon-bold" style="margin-top: 15px; text-align: center; color: white; font-size: 22px; text-align: center; font-weight: 500;">{{ texts.buyer.Warehouses }}</h4>
            </div>
          </div>
          <div style="background: #FFFFFF50 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000029; border-radius: 8px; width: 217px; height: 201px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
            <div style="display: flex; flex-direction: column; align-items: center;">
              <v-img src="@/assets/buyer/PNG/user.png" alt="Descripción de la imagen" contain max-width="60" max-height="50"></v-img>
              <h4 class="mon-bold" style="margin-top: 15px; text-align: center; color: white; font-size: 22px; text-align: center; font-weight: 500;">{{ texts.buyer.Suppliers }}</h4>
            </div>
          </div>
          <div style="background: #FFFFFF50 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000029; border-radius: 8px; width: 217px; height: 201px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
            <div style="display: flex; flex-direction: column; align-items: center;">
              <v-img src="@/assets/buyer/PNG/bookmark.png" alt="Descripción de la imagen" contain max-width="60" max-height="50"></v-img>
              <h4 class="mon-bold" style="margin-top: 15px; text-align: center; color: white; font-size: 22px; text-align: center; font-weight: 500;">{{ texts.buyer.Orders }}</h4>
            </div>
          </div>
          <!-- <div style="background: #FFFFFF50 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000029; border-radius: 8px; width: 217px; height: 201px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
            <div style="display: flex; flex-direction: column; align-items: center;">
              <v-img src="@/assets/buyer/PNG/alert-triangle.png" alt="Descripción de la imagen" contain max-width="60" max-height="50"></v-img>
              <h4 class="mon-bold" style="margin-top: 15px; text-align: center; color: white; font-size: 22px; text-align: center; font-weight: 500;">{{ texts.buyer.Dispute }}</h4>
            </div>
          </div> -->
          <div style="background: #FFFFFF50 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000029; border-radius: 8px; width: 217px; height: 201px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
            <div style="display: flex; flex-direction: column; align-items: center;">
              <v-img src="@/assets/buyer/PNG/shopping-bag.png" alt="Descripción de la imagen" contain max-width="60" max-height="50"></v-img>
              <h4 class="mon-bold" style="margin-top: 15px; text-align: center; color: white; font-size: 22px; text-align: center; font-weight: 500;">{{ texts.buyer.User }}</h4>
            </div>
          </div>
        </div>
        <div style="text-align: center; margin-top: 50px; margin-bottom: 30px;">
          <a href="https://www.proplat.com/welcome" target="_blank">
            <v-btn
            class="button-register mon-bold ml-3"
            >
              {{ texts.buyer.buttonBuy }}
            </v-btn>
          </a>
        </div>
        <div style="display: flex; justify-content: center; flex-wrap: wrap; align-items: center;">
          <v-img class="hidden-sm-and-down" src="@/assets/buyer/Grupo 26855.png" alt="Descripción de la imagen" contain max-width="874" max-height="691"></v-img>            
          <v-img v-if="selectLanguage == 'en'" src="@/assets/buyer/buyerEN.png" alt="Descripción de la imagen" contain max-width="377" max-height="580"></v-img>
          <v-img v-if="selectLanguage == 'sp'" src="@/assets/buyer/buyerES.png" alt="Descripción de la imagen" contain max-width="377" max-height="580"></v-img>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
    selectLanguage: {
      type: String,
      default: "",
    },
  },
  name: "HomeCatalogTypeSkeletonLayout",
};
</script>
<style scoped>
.button-register {
  width: 260px !important;
  height: 59px !important;
  background: transparent linear-gradient(256deg, #ffc431 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d !important;
  font-size: 16px !important;
  text-transform: initial;
  padding: 20px 20px;
}
</style>